<template>
  <div>
    <b-row class="container-homepage">
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <h1 class="h1-home">Here to Help:</h1>
            <h2 class="h2-home">No Barriers to Problem Gambling Treatment</h2>

            <a href="http://www.nyproblemgambling.org/" target="_blank" class="logo-homepage">
              <div style="background-color: #94221e; padding: 10px; margin-bottom: 5px;">
                <b-img
                  thumbnail
                  fluid
                  :src="require('@/assets/images/logo/nycpg-logo-white.svg')"
                  style="text-align: center; font-size: 16px; color: #94221e; background: #94221e; border: none;"
                  alt="Image 1"
                />
                
              </div>
              <div class="logo-text">New York Council on Problem Gambling</div>
            </a>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col md="12">
            <b-img
              thumbnail
              fluid
              :src="require('@/assets/images/illustration/nys-counties-transparent-2d26ec2c95c5a3bdd11e0c7ad7591719.png')"
              alt="Image 1"
              style="background: none; border: none;"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row style="background: #fff; padding: 30px;">
      <b-col md="12" style="display: flex;">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          href="https://us15.list-manage.com/subscribe?u=dd8378f2da5e3f64d3945ff5f&id=526246de09"
          target="_blank"
        >
          <feather-icon
            icon="MailIcon"
            class="mr-50"
          />
          Subscribe
        </b-button>
        <h4 class="mt-50 ml-2">To receive updates and announcements from NYCPG</h4>
      </b-col>
    </b-row>
    <!-- <b-container class="bv-example-row container-AccessControl">
      <b-row>
        <div class="w-100">
          <vuexy-logo style="margin: auto; display: block;" class="mt-0" />
        </div>
        <b-card-text class="mx-auto mt-1">Welcome <span style="font-weight: bold !important; font-size: 20px !important; font-family: GilroyBold, sans-serif !important;">{{ usuario.nombre }}!</span></b-card-text>
        <b-card-text class="mx-auto w-100" style="display: flex;">
          <b-link
            class="font-weight-bold d-block text-nowrap mx-auto mt-1"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Sign out</span>
          </b-link>
        </b-card-text>
          <b-img
            thumbnail
            fluid
            :src="require('@/assets/images/illustration/nys-counties-transparent-2d26ec2c95c5a3bdd11e0c7ad7591719.png')"
            alt="Image 1"
          />
      </b-row>
    </b-container> -->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAvatar, BListGroup, BListGroupItem, BLink, BImg,
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BContainer, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import { mapActions, mapState } from "vuex"
import { db, firebase } from '@/firebase'

export default {
  data() {
    return {
      nameState: null,
      selected: 'USA',
      option: ['USA', 'Canada', 'Mexico'],
      userInvitation: '',
      boxTwo: '',
      loadPage: true,
      cuentaVerificada: true,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Access control' }
    ]
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  watch: {
    boxTwo(val) {
      if(val) {
        this.confirmInvitation()
      } else if(val === false) {
        this.rejectInvitation()
      }
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BListGroup,
    BListGroupItem,
    VuexyLogo,
    BLink,
    BImg,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BContainer,
  },
  setup(props) {
    return {
      avatarText
    }
  },
  computed:{
    ...mapState(['usuario'])
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    logout() {
      // cerrar sesión
      // localStorage.removeItem('logActy')

      this.cerrarSesion()
    }
  },
  created() {
    // var logLastActivity = localStorage.getItem('logActy')
    // var fechaInicio = moment(Date.now());
    // var fechaFin = moment(parseInt(logLastActivity,10));

    // var diff = fechaInicio.diff(fechaFin, 'minutes');
    
    // if(diff >= 15) {
    //   // console.log(diff)  
    //   this.logout()
    // }

    // var user = firebase.auth().currentUser
    // // var newPassword = getASecureRandomPassword();
    // var newPassword = '123456'
    // console.log(user, newPassword)

    // user.updatePassword(newPassword).then(function() {
    // // Update successful.
    // }).catch(function(error) {
    //   // An error happened.
    //   console.log('Err:',error);
    // });
  }
}
</script>

<style>
.container-homepage {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(0, rgba(102,102,102,.25)),to(rgba(102,102,102,.65)));
  background: -webkit-linear-gradient(top,rgba(102,102,102,.25) 0,rgba(102,102,102,.65) 100%);
  background: -o-linear-gradient(top,rgba(102,102,102,.25) 0,rgba(102,102,102,.65) 100%);
  background: linear-gradient(to bottom,rgba(102,102,102,.25) 0,rgba(102,102,102,.65) 100%);
}
.avatarUser-accessControl > span {
  font-size: 28px !important;
}
.container-AccessControl {
  max-width: 1300px;
  margin: auto;
}
.teams-card-nombre {
  margin-left: 25px;
  position: absolute;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
}
.h1-home {
  color: #fff !important;
  text-align: center;
  margin-bottom: 0;
  text-shadow: 2px 2px 2px #888;
  font-size: 63px;
  margin-top: 20px;
}
.h2-home {
  color: #fff !important;
  text-align: center;
  margin-bottom: 0;
  text-shadow: 2px 2px 2px #888;
  font-size: 30px;
  margin-top: 20px;
}
.logo-homepage {
  display: block;
  max-width: 350px;
  margin: 40px auto 0;
  padding: 10px;
  background: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-family: Georgia,"Times New Roman",Times,serif;
  color: #94221e;
  border-radius: 3px;
}
</style>
